<template>
  <div class="md:p-4">
    <div class="max-w-2xl">
      <sqr-page-header class="p-4" title="Prochains anniversaires" />
      <sqr-progress v-show="loading" />
      <sqr-error-banner :error="loadError" />
      <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <ul>
          <li
            v-for="relation in result.hits"
            :key="relation.objectID"
            class="border-t border-gray-300 first:border-t-0"
          >
            <div
              class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
            >
              <div class="flex items-center px-4 py-4 sm:px-6">
                <div class="min-w-0 flex-1 flex items-center">
                  <div
                    class="min-w-0 flex-1 px-4 md:grid md:grid-cols-4 md:gap-4"
                  >
                    <div
                      class="truncate text-sm leading-5 font-medium text-blue-600 truncate"
                    >
                      <router-link
                        :to="{
                          name: 'relation-general',
                          params: { bid, rid: relation.objectID }
                        }"
                      >
                        <span class="mr-1">{{ relation.givenName }}</span>
                        <span class="font-semibold">{{
                          relation.familyName
                        }}</span>
                      </router-link>
                    </div>
                    <div
                      class="text-sm text-gray-700 md:text-center leading-5 text-gray-900"
                    >
                      <time :datetime="relation.birthDate">{{
                        relation.birthDate | date
                      }}</time>
                    </div>
                    <div
                      class="text-sm text-gray-700 md:text-center leading-5 text-gray-900"
                    >
                      <a
                        v-if="relation.email"
                        :href="'mailto:' + relation.email"
                        target="_blank"
                        >email</a
                      >
                    </div>
                    <div
                      class="text-sm text-gray-700 md:text-center leading-5 text-gray-900"
                    >
                      <a
                        v-if="relation.telephoneMobile"
                        :href="'tel:' + relation.telephoneMobile"
                        target="_blank"
                        >mobile</a
                      >
                    </div>
                  </div>
                </div>
                <div class="text-blue-600">
                  <fa :icon="['fal', 'chevron-right']" />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import { contains, zipObj, sortBy } from 'ramda';
import broker from './broker';
import date from '@/filters/date';
import SqrPageHeader from '@/sqrd-ui/SqrPageHeader';
import SqrProgress from '@/sqrd-ui/SqrProgress';
import SqrErrorBanner from '@/sqrd-ui/SqrErrorBanner';
export default {
  name: 'Birthdays',
  mixins: [broker],
  components: { SqrPageHeader, SqrProgress, SqrErrorBanner },
  filters: { date },
  data() {
    return {
      loading: false,
      loadError: null,
      result: {}
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions('search', ['searchClientGet']),
    async load() {
      try {
        const bid = this.bid;

        const today = DateTime.local();
        const dates2 = [0, 1, 2, 3]
          .map(day => today.plus({ day }))
          .map(date => date.toISODate().substr(5, 5));
        const dates1 = contains('02-28', dates2)
          ? [...dates2, '02-29']
          : dates2;
        const dates = dates1.map(date => `birthDay:${date}`);
        const filters = dates.join(' OR ');

        const client = await this.searchClientGet({ bid });
        const index = client.initIndex('relations');
        const result = await index.search('', { filters, hitsPerPage: 100 });
        this.result = result;
      } catch (error) {
        this.loadError = error;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
